import React from "react";
const DoctorCard = ({ doctor }) => {
  const photoURL =
    doctor.photo &&
    doctor.photo[0] &&
    doctor.photo[0].thumbnails &&
    doctor.photo[0].thumbnails.large
      ? doctor.photo[0].thumbnails.large.url
      : null;

  return (
    <div className="bg-white bg-opacity-30 backdrop-blur-md p-6 rounded-lg shadow-lg space-y-4">
      {photoURL && (
        <img
          src={photoURL}
          alt={`Dr. ${doctor.name}`}
          className="w-48 h-48 mx-auto rounded-full shadow-md"
        />
      )}
      <h2 className="text-2xl font-semibold text-deep-blue text-center">
        {doctor.name}
      </h2>
      <p className="text-xl text-light-gray text-center">
        {doctor.designation}
      </p>
      <p className="text-lg text-gray-700 text-center">{doctor.email}</p>
      <p className="text-lg text-gray-700 text-center">{doctor.contact}</p>
    </div>
  );
};

export default DoctorCard;
