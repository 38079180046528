import React, { useState, useEffect } from "react";
import styles from "./Homepage.module.css";
import photo1 from "../../assets/photo1.jpg";
import photo2 from "../../assets/photo2.jpg";
import DoctorsDirectory from "../DoctorsDirectory";

// // Inside HomePage component's render:
// <DoctorsDirectory />;

const HomePage = () => {
  const images = [photo1, photo2];
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [currentSet, setCurrentSet] = useState(0);
  const imageWidth = 256; // Assuming each image (with margins) takes up 256 pixels. Adjust as needed.
  const imagesPerSet = Math.floor(viewportWidth / imageWidth);

  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-soft-gray min-h-screen flex flex-col items-center justify-center p-6 md:p-12 space-y-10">
      {/* Navigation Bar */}
      <nav className="main-navigation w-full max-w-5xl bg-white p-4 mb-10 rounded-lg shadow-lg flex justify-around">
        <a
          href="#DoctorsDirectory"
          className="text-deep-blue hover:text-vibrant-teal"
        >
          Doctors
        </a>
        <a
          href="#faq-section"
          className="text-deep-blue hover:text-vibrant-teal"
        >
          FAQ
        </a>
        <a
          href="#timings-section"
          className="text-deep-blue hover:text-vibrant-teal"
        >
          Timing
        </a>
        <a
          href="https://www.google.com/maps/dir//'23.368033,85.348144'/@23.3680219,85.3429741,16z/data=!4m6!4m5!1m0!1m3!2m2!1d85.348144!2d23.368033?entry=ttu"
          className="text-deep-blue hover:text-vibrant-teal"
        >
          Direction
        </a>
        {/* <a href="#" className="text-deep-blue hover:text-vibrant-teal">
          Patient
        </a>
        <a href="#" className="text-deep-blue hover:text-vibrant-teal">
          Testimonials
        </a> */}
      </nav>

      <div className="flex flex-col md:flex-row items-center justify-between w-full md:space-x-12 space-y-8 md:space-y-0">
        <div className="flex flex-col space-y-6 items-center w-full md:w-1/2 text-center md:text-left px-4 md:px-0">
          <h1 className="text-6xl font-extrabold text-deep-blue mb-4">
            Ali Surgicare Centre
          </h1>
          <p className="text-lg md:text-xl text-light-gray max-w-2xl mx-auto">
            At Ali Surgicare Center, under the esteemed guidance of Dr. B. Doja,
            our mission transcends the boundaries of traditional medical care.
            Recognized as one of the city's leading surgical luminaries, Dr.
            Doja's commitment to surgical excellence is matched only by our
            dedication to the community's well-being.
          </p>
          <button className="bg-vibrant-teal text-white px-8 py-3 rounded-full text-lg hover:bg-teal-dark focus:outline-none focus:ring-2 focus:ring-teal-light mt-4">
            Learn More
          </button>
        </div>

        <div className="w-full md:w-1/2 flex justify-center space-x-2 md:space-x-6">
          {currentSet > 0 && (
            <button
              onClick={() => setCurrentSet(currentSet - 1)}
              className="p-2 bg-transparent text-deep-blue hover:text-vibrant-teal"
            >
              &larr;
            </button>
          )}
          {images
            ?.slice(currentSet * imagesPerSet, (currentSet + 1) * imagesPerSet)
            ?.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Hospital Image ${currentSet * imagesPerSet + index + 1}`}
                className="w-56 md:w-64 my-2 rounded-lg shadow-xl transform transition-transform duration-300 hover:scale-110"
              />
            ))}
          {(currentSet + 1) * imagesPerSet < images.length && (
            <button
              onClick={() => setCurrentSet(currentSet + 1)}
              className="p-2 bg-transparent text-deep-blue hover:text-vibrant-teal"
            >
              &rarr;
            </button>
          )}
        </div>
      </div>

      <div id="DoctorsDirectory">
        <DoctorsDirectory />
      </div>

      {/* FAQ Section with Glass Theme */}
      <div
        id="faq-section"
        className="faq-section w-full max-w-5xl bg-blue-100 bg-opacity-30 backdrop-blur-md p-8 rounded-lg shadow-lg space-y-6"
      >
        <h2 className="text-3xl font-semibold text-deep-blue mb-6">
          Frequently Asked Questions
        </h2>

        {/* Individual FAQ */}
        <div className="border-t border-light-gray pt-4">
          <button className="w-full text-left text-xl font-medium text-deep-blue focus:outline-none">
            What services do you offer?
          </button>
          <p className="text-lg text-gray-700 mt-2">
            We offer a range of services including Laproscopic general surgery,
            and surgery for hernia, piles, kidney stone, gallbladder stones,
            circumcision and more.
          </p>
          <button className="w-full text-left text-xl font-medium text-deep-blue focus:outline-none">
            What testing services do you offer?
          </button>
          <p className="text-lg text-gray-700 mt-2">
            We have ECG and state of the art Pathology lab.
          </p>
          <button className="w-full text-left text-xl font-medium text-deep-blue focus:outline-none">
            What are out bed and cabin charges?
          </button>
          <p className="text-lg text-gray-700 mt-2">
            Our bed and cabin charges are very minimal with all state of the art
            facilities.
          </p>
        </div>

        {/* ... (more FAQs) ... */}
      </div>

      {/* Timings Section with Glass Theme */}
      <div
        id="timings-section"
        className="timings-section flex flex-col md:flex-row items-center justify-between w-full md:space-x-12 space-y-6 md:space-y-0"
      >
        {/* Operational Timings Section */}
        <div className="w-full md:w-1/2 bg-blue-100 bg-opacity-30 backdrop-blur-md text-deep-blue p-6 rounded-lg shadow-lg text-center space-y-4">
          <h2 className="text-3xl font-semibold mb-4">Operational Timings</h2>
          <p className="text-lg">Monday - Friday: 10:00 AM - 8:00 PM</p>
          <p className="text-lg">Saturday: 10:00 AM - 08:00 PM</p>
          <p className="text-lg">Sunday: Closed</p>
          <p className="text-lg mt-2 italic text-light-gray">
            * Timings are subject to change
          </p>
        </div>

        {/* OPD Timings Section */}
        <div className="w-full md:w-1/2 bg-blue-100 bg-opacity-30 backdrop-blur-md text-deep-blue p-6 rounded-lg shadow-lg text-center space-y-4">
          <h2 className="text-3xl font-semibold mb-4">OPD Timings</h2>
          <p className="text-lg">Monday - Saturday : 10:00 AM - 2:00 PM </p>
          <p className="text-lg">And 5:00PM - 7:00 PM </p>
          <p className="text-lg">Sunday: Closed</p>
          <p className="text-lg mt-2 italic text-light-gray">
            * Timings are subject to change
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
