import React, { useState, useEffect } from "react";
import DoctorCard from "./DoctorCard";
import {
  AIRTABLE_API_KEY,
  AIRTABLE_BASE_ID,
  AIRTABLE_TABLE_NAME,
} from "./doctorsData";

const VISIBLE_COUNT = 3; // You can adjust this number based on your requirements

const DoctorsDirectory = () => {
  const [doctors, setDoctors] = useState([]);
  const [startIdx, setStartIdx] = useState(0);

  const fetchDoctors = async (offset) => {
    let url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_TABLE_NAME}?pageSize=100`;
    if (offset) {
      url += `&offset=${offset}`;
    }

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${AIRTABLE_API_KEY}`,
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    setDoctors((prevDoctors) => [
      ...prevDoctors,
      ...data.records?.map((record) => record.fields),
    ]);

    // If there's an offset in the response, fetch the next set of records
    if (data.offset) {
      fetchDoctors(data.offset);
    }
  };

  useEffect(() => {
    fetchDoctors();
  }, []);

  const visibleDoctors = doctors.slice(startIdx, startIdx + VISIBLE_COUNT);

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {visibleDoctors?.map((doctor, index) => (
          <DoctorCard key={index} doctor={doctor} />
        ))}
      </div>

      <div className="flex space-x-4">
        {startIdx > 0 && (
          <button
            onClick={() => setStartIdx(startIdx - VISIBLE_COUNT)}
            className="p-2 bg-transparent text-deep-blue hover:text-vibrant-teal"
          >
            &larr;
          </button>
        )}

        {doctors.length > startIdx + VISIBLE_COUNT && (
          <button
            onClick={() => setStartIdx(startIdx + VISIBLE_COUNT)}
            className="p-2 bg-transparent text-deep-blue hover:text-vibrant-teal"
          >
            &rarr;
          </button>
        )}
      </div>
    </div>
  );
};

export default DoctorsDirectory;
